.discordButton {
  display: inline-block !important;
  vertical-align: top !important;
  background-color: rgb(114 137 218) !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  border: none !important;
  color: #fff !important;
  cursor: pointer !important;
  font-size: 16px !important;
  line-height: 20px !important;
  padding: 9px 21px 11px !important;
  border-radius: 20px !important;
  text-decoration: none !important;
}

.discordButton:hover {
  text-decoration: none !important;
  background-color: rgb(114 137 218) !important;
}
