.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container {
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid;
  border-color: var(--chakra-colors-whiteAlpha-300);
}

.react-datepicker__input-container:hover {
  border-color: var(--chakra-colors-whiteAlpha-400);
}
.react-datepicker__input-container:focus-within {
  z-index: 1;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
  background-color: inherit;
}
